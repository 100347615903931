import { useState, useCallback, useContext } from 'react';
import AuthContext from '../stores/auth-context';

const useHttp = () => {
  const [error, setError] = useState(null);
  const { token, user } = useContext(AuthContext);
  const sendRequest = useCallback(async (requestConfig) => {
    setError(null);
    try {
      let bearer = 'Bearer ' + token.replaceAll('"', '');
      let headers = token
        ? {
            Authorization: bearer,
            Accept: 'application/json',
          }
        : { Accept: 'application/json' };

      // if(requestConfig[Content-Type] === 'multipart/form-data')

      let body =
        requestConfig['Content-Type'] === undefined
          ? JSON.stringify(requestConfig.body)
          : JSON.stringify(requestConfig.body);

      const response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers
          ? { ...requestConfig.headers, ...headers }
          : { ...headers },
        body: body,
      });

      if (!response.ok) {
        throw new Error('Request failed!');
      }

      const data = await response.json();
      return data;
    } catch (err) {
      setError(err.message || 'Something went wrong!');
    }
  }, []);

  return {
    error,
    sendRequest,
  };
};

export default useHttp;
