import { AutoComplete, Form, Input, Select } from 'antd';
import React from 'react';

const { Option } = AutoComplete;


const SecondStepEditForm = ({
  employe,
  positions,
  superiors,
  setPosition,
  setSuperior,
  coefficients
}) => {
  
  const onPositionsSelectd = (value, option) => {
    setPosition({ id: option.key, name: option.value });
  };
  const onSuperiorSelectd = (value, option) => {
    setSuperior({ id: option.key, name: option.value });
  };
  return (
    <>
      <Form.Item name="position" label="Pozicija">
        <AutoComplete onSelect={onPositionsSelectd}>
          {positions.map((obj) => (
            <Option key={obj.id} value={obj.name}>
              {obj.name}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
      <Form.Item name="superior" label="Nadredjeni">
        <AutoComplete onSelect={onSuperiorSelectd}>
          {superiors.map((obj) => (
            <Option key={obj.id} value={obj.full_name}>
              {obj.full_name}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
      <Form.Item name="coefficient" label="Koeficijenti">
      <Select 
          placeholder="Izaberite koeficijenti" 
          className="mb-15 mt-5 mw-200" 
        >
          {coefficients.map((item) => {
            return(
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default SecondStepEditForm;
