import React, { useRef } from 'react';
import {
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined } from '@ant-design/icons';

const FirstStepEditForm = ({ employe, fileList, setFileList }) => {
  const { Option } = Select;
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  return (
    <>
      {/* full_name */}
      <Form.Item
        label="Ime i prezime"
        name="full_name"
        rules={[
          {
            required: true,
            message: 'Molim vas unesite ime i prezime!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Fotografija">
        <ImgCrop rotate>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload>
        </ImgCrop>
      </Form.Item>

      {/* birthday */}
      <Form.Item
        label="Datum rođenja"
        name="birthday"
        rules={[
          {
            required: true,
            message: 'Molim vas unesite datum rođenja!',
          },
        ]}
      >
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>

      {/* num_phone */}
      <Form.Item label="E-mail adresa" name="email" rules={[{ type: 'email' }]}>
        <Input />
      </Form.Item>

      {/* num_phone */}
      <Form.Item label="Broj telefona" name="num_phone">
        <Input />
      </Form.Item>

      {/* jmbg */}
      <Form.Item label="JMBG" name="jmbg">
        <Input />
      </Form.Item>

      {/* id_number */}
      <Form.Item label="Broj licne karte" name="id_number">
        <Input />
      </Form.Item>

      {/* id_number */}
      <Form.Item label="Broj radne knjižice" name="num_work_book">
        <Input />
      </Form.Item>

      {/* parent_name */}
      <Form.Item label="Ime roditelja" name="parent_name">
        <Input />
      </Form.Item>

      {/* gender */}
      <Form.Item
        label="Pol"
        name="gender"
        rules={[
          {
            required: true,
            message: 'Molim vas unesite pol!',
          },
        ]}
      >
        <Select placeholder="Odaberite pol zaposlenog">
          <Option value="male">Muški</Option>
          <Option value="female">Ženski</Option>
        </Select>
      </Form.Item>

      {/* adress */}
      <Form.Item label="Adresa" name="adress">
        <Input />
      </Form.Item>

      {/* birth_place */}
      <Form.Item label="Mjesto rođenja" name="birth_place">
        <Input />
      </Form.Item>

      {/* place_work */}
      <Form.Item label="Mjesto rada" name="place_work">
        <Input />
      </Form.Item>

      {/* neto_selery */}
      <Form.Item label="Neto zarada" name="neto_selery">
        <Input />
      </Form.Item>

      <Form.Item label="Bruto 1 zarada" name="bruto_1">
        <Input />
      </Form.Item>

      <Form.Item label="Bruto 2 zarada" name="bruto_2">
        <Input />
      </Form.Item>

      {/* number_work_hours */}
      <Form.Item label="Broj radnih sati" name="number_work_hours">
        <InputNumber />
      </Form.Item>

      {/* number_work_hours */}
      <Form.Item label="Pocetak probnog rad" name="probation_period_start">
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>

      {/* number_work_hours */}
      <Form.Item label="Kraj probnog rad" name="probation_period_end">
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>

      {/* indefinitely_work */}
      <Form.Item
        label="Rad na neodređeno vrijeme"
        name="indefinitely_work"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
    </>
  );
};

export default FirstStepEditForm;
