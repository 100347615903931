import React, { useEffect, useState } from 'react';
import useHttp from '../../hooks/use-http';
import { Table, Space, Button, Input, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import '../EmployesPage/EmployesPage.css';
import { baseUrl } from '../../share/share';

const { Option } = Select;



const WorkPosition = () => {
  const [positions, setPositions] = useState([]);
  const [modal, setModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPosition, setCurrentPosition] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [coefficients, setCoefficients] = useState([]);
  const [coefficient, setCoefficient] = useState(null);

  const { errorCoeff, sendRequest: coeffFetch } = useHttp();
  const { error, sendRequest: featchPositions } = useHttp();
  const { changeError, sendRequest: saveChangePositions } = useHttp();

  const { isLoaderNew, newError, sendRequest: saveNewPositions } = useHttp();
  const { errorDelete, sendRequest: deletePositions } = useHttp();
  
  useEffect(async () => {
    
    let prom = [
      featchPositions({
        url: baseUrl + '/work-postitions/all',
      }),
      coeffFetch({
        url: baseUrl + '/coefficient/all',
      })
    ]
    Promise.all(prom).then(async (datas) => {
      const [wp, coeff] = datas;
      setPositions(wp.data);
      setCoefficients(coeff);
      setIsLoader(false);
    });
     
  }, [featchPositions]);

  const saveChangePosition = async () => {
    setIsLoader(true);
    const changeData = data => {
      closeModal();
      let index = positions.findIndex((item) => item.id === currentPosition.id);
      positions[index] = data;
      setPositions([...positions]);
      setIsLoader(false);
    };

    const newData = (data) => {
      closeModal();

      positions.push(data);
      setPositions([...positions]);
      setIsLoader(false);
    };

    let obj = {
      name: currentPosition.name,
      description: currentPosition.description, 
      coefficient: coefficient
    }

    if (currentPosition.id) {
      obj.id = currentPosition.id;
      let data = await saveChangePositions({
        url: baseUrl + '/work-postitions/' + currentPosition.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: obj,
      });
      changeData(data);
    } else {
      let data = await saveNewPositions({
        url: baseUrl + '/work-postitions/create',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: obj,
      });
      newData(data);
    }
  };

  const closeModal = () => {
    setModal(false);
    setCoefficient(null);
  };

  const editPosition = (item) => {
    setCurrentPosition(item);
    if(item && item.coefficient) setCoefficient(Number(item.coefficient));
    setModal(true);
    setDisabled(true);
  };

  const newPosition = (event) => {
    event.preventDefault();
    setCurrentPosition('');
    setModal(true);
    setDisabled(true);
    setCoefficient(null);
  };

  const changeName = (event) => {
    let name = event.target.name;
    let val = event.target.value;

    setCurrentPosition({ ...currentPosition, [name]: val });
    setDisabled(false);
  };

  const onCoeffSelectd = (value) => {
    // console.log(value)
    // let item = coefficients[coefficients.findIndex(item => item.id === value.value)];
    
    setCoefficient(value);
  }

  const deletePosition = (id) => {
    
    const deleteData = () => {
      let index = positions.findIndex((pos) => pos.id === id);
      let array = [...positions];
      array.splice(index, 1);
      setPositions([...array]);
    };
    deletePositions(
      {
        url: baseUrl + '/work-postitions/' + id,
        method: 'DELETE',
      },
      deleteData
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      
    },
    {
      title: 'Opis',
      dataIndex: 'description',
    },
    {
      title: 'Koeficijenti',
      dataIndex: 'coefficient',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <span className="link" onClick={() => editPosition(record)}>Izmijeni</span>
          <span className="link" onClick={() => deletePosition(record.id)}>Obriši</span>
        </Space>
      ),
    },
  ];

  
  

  return (
    <>
      <Button type="primary" onClick={newPosition}>
        + Nova pozicija
      </Button>
      {!isLoader && (
        <Table
          columns={columns}
          dataSource={positions}
          rowKey="id"
          pagination={false}
          scroll={{
            y: 'calc(100vh - 190x)',
            x: '100%',
          }}
        />
      )}
      <Modal
        title={currentPosition.id ? 'Promijeni ime pozicije' : 'Nova pozicija'}
        centered
        visible={modal}
        onOk={() => saveChangePosition()}
        onCancel={() => closeModal()}
        okButtonProps={{ disabled: disabled }}
      >
        <label>Ime pozicije: </label>
        <Input 
          value={currentPosition.name} 
          onChange={changeName} 
          className="mb-15 mt-5"
          name="name" 
        />
        <label>Opis pozicije: </label>
        <Input.TextArea 
          rows={4} 
          value={currentPosition.description} 
          onChange={changeName} 
          className="mb-15 mt-5" 
          name="description"
        />
        <label>Minimalni koeficijent pozicije: </label>
        <Select 
          placeholder="Izaberite koeficijenti"  
          onChange={onCoeffSelectd} 
          value={coefficient} 
          className="mb-15 mt-5 mw-200" 
        >
          {coefficients.map((item) => {
            return(
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
        </Select>
      </Modal>
    </>
  );
};

export default WorkPosition;
