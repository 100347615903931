import React, { useContext, useEffect, useState } from 'react';

import { Layout, Menu } from 'antd';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  IdcardOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import MainNavigation from './MainNavigation ';
import AuthContext from '../../stores/auth-context';
import './layout.css';

const navigateParams = {
  employes: 'employes',
  employ: 'employes',
  workPosition: 'work-position',
  companies: 'companies',
  coefficient: 'coefficient',
};

function camelize(text) {
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return text.substr(0, 1).toLowerCase() + text.substr(1);
}

const getPerentPath = (str) => {
  if (str[0] === '/') str = str.substring(1);
  let arr = str.split('/');

  return arr[1];
};


const LayoutComponent = ({ children }) => {
  const { Content, Sider } = Layout;
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useContext(AuthContext);
  const [current, setCurrent] = useState('');

  let location = useLocation();
  useEffect(() => {
    let pathname = getPerentPath(location.pathname);
    let curentObj = camelize(pathname);

    setCurrent(navigateParams[curentObj]);
  }, [setCurrent]);

  useEffect(() => {
    if(user && (typeof user === 'string' && JSON.parse(user).is_sysadmin || user.is_sysadmin)) setIsAdmin(true);
  }, [user]);

  const handleClickMenu = (event) => {
    setCurrent(event.key);
  };

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const { SubMenu } = Menu;
  return (
    <Layout className="vh_100">
      {/* Header */}
      <MainNavigation />
      {/* End Header */}

      {/* Left sidebar Menu */}
      <Layout>
        <Sider width={200} className="site-layout-background">
          {/* Menu */}
          <Menu
            mode="inline"
            selectedKeys={[current]}
            onClick={handleClickMenu}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item icon={<UsergroupAddOutlined />} key="employes">
              <Link to="/dashbord/employes">Zaposleni</Link>
            </Menu.Item>
            <Menu.Item icon={<IdcardOutlined />} key="work-position">
              <Link to="/dashbord/work-position">Pozicije zaposlenih</Link>
            </Menu.Item>
            
            { isAdmin && (
                <>
                  <Menu.Item icon={<AreaChartOutlined />} key="companies">
                    <Link to="/dashbord/companies">Kompanije</Link>
                  </Menu.Item>
                
                  <Menu.Item icon={<BarChartOutlined />} key="coefficient">
                    <Link to="/dashbord/coefficient">Koeficijenti</Link>
                  </Menu.Item>

                  <Menu.Item icon={<UserOutlined />} key="users">
                    <Link to="/dashbord/users">Users</Link>
                  </Menu.Item>
                </>
              )
            }
            
            {/* <SubMenu key="sub1" title="subnav 1">
              <Menu.Item key="2">option2</Menu.Item>
              <Menu.Item key="3">option3</Menu.Item>
              <Menu.Item key="4">option4</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<LaptopOutlined />} title="subnav 2">
              <Menu.Item key="5">option5</Menu.Item>
              <Menu.Item key="6">option6</Menu.Item>
              <Menu.Item key="7">option7</Menu.Item>
              <Menu.Item key="8">option8</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<NotificationOutlined />}
              title="subnav 3"
            >
              <Menu.Item key="9">option9</Menu.Item>
              <Menu.Item key="10">option10</Menu.Item>
              <Menu.Item key="11">option11</Menu.Item>
              <Menu.Item key="12">option12</Menu.Item>
            </SubMenu> */}
          </Menu>
          {/* Menu */}
        </Sider>
        <Layout>
          <Content className="site-layout-background content m-10">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      {/* END Left sidebar mani */}
    </Layout>
  );
};

export default LayoutComponent;
