import { Button, Input, Modal, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import useHttp from '../../hooks/use-http';
import { baseUrl } from '../../share/share';

const Coefficient = () => {
  const [coefficients, setCoefficients] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [modal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [coefficient, setCoefficient] = useState({});

  const { errorFetch, sendRequest: dataFetch } = useHttp();
  const { errorDelete, sendRequest: dataDelete } = useHttp();
  const { changeError, sendRequest: saveChangeData } = useHttp();
  const { saveError, sendRequest: saveNewData } = useHttp();

  const editData = (data) => {
    setCoefficient(data);
    setModal(true);
    setDisabled(true);
  };
  const editModalData = () => {
    setCoefficient({});
    setModal(true);
    setDisabled(true);
  };

  const closeModal = () => {
    setModal(false);
    setCoefficient({});
  };

  const saveChangeOrCreate = async () => {
    setIsLoader(true);

    const changeData = (data) => {
      closeModal();

      let index = coefficients.findIndex((item) => item.id === data.id);
      coefficients[index] = data;
      setCoefficients([...coefficients]);
      setIsLoader(false);
    };

    const newData = (data) => {
      closeModal();
      coefficients.push(data);
      setCoefficients([...coefficients]);
      setIsLoader(false);
    };

    if (coefficient.id) {
      let data = await saveChangeData({
        url: baseUrl +  '/coefficient/' + coefficient.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: coefficient,
      });
      changeData(data);
    } else {
      let data = await saveNewData({
        url: baseUrl + '/coefficient/create',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: coefficient,
      });
      newData(data);
    }
  };

  const dataCnageDelete = (data) => {
    let index = coefficients.findIndex((item) => item.id === data.id);

    coefficients.splice(index, 1);

    setCoefficients([...coefficients]);
  };

  const deleteData = (data) => {
    let item = dataDelete({
      url: baseUrl +  '/coefficient/' + data.id,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dataCnageDelete(data);
  };

  const columns = [
    {
      title: 'Ime',
      dataIndex: 'name',
    },
    {
      title: 'Koeficijenti',
      dataIndex: 'coefficient',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {/* <Popconfirm
            title="Da li ste sigurni da želite da obrišete poziciju?"
            onConfirm={() => deletePosition(record.id)}
          >
            <a>Obriši</a>
          </Popconfirm> */}
          <span className="link" onClick={() => editData(record)}>
            Izmijeni
          </span>
          <span className="link" onClick={() => deleteData(record)}>
            Obriši
          </span>
        </Space>
      ),
    },
  ];

  const hendlerInput = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    if (coefficient.name != '' && coefficient.coefficient != '')
      setDisabled(false);
    setCoefficient({ ...coefficient, [name]: val });
  };

  useEffect(async () => {
    const transformData = (data) => {
      setCoefficients([...data]);
      setIsLoader(false);
    };
    let data = await dataFetch({
      url: baseUrl +  '/coefficient/all',
    });
    transformData(data);
  }, [dataFetch]);
  return (
    <>
      <Button type="primary" onClick={editModalData}>
        + Nova kompanija
      </Button>
      {!isLoader && (
        <Table
          columns={columns}
          dataSource={coefficients}
          rowKey="id"
          pagination={false}
          scroll={{
            y: 'calc(100vh - 190x)',
            x: '100%',
          }}
        />
      )}

      <Modal
        title={coefficient.id ? 'Promijeni ime kompanije' : 'Nova kompanija'}
        centered
        visible={modal}
        onOk={() => saveChangeOrCreate()}
        onCancel={() => closeModal()}
        okButtonProps={{ disabled: disabled }}
      >
        <Input value={coefficient.name} name="name" onChange={hendlerInput} />
        <Input
          value={coefficient.coefficient}
          name="coefficient"
          onChange={hendlerInput}
        />
      </Modal>
    </>
  );
};

export default Coefficient;
