import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';

import LayoutComponent from './components/Layout/LayoutComponent';
import AuthPage from './pages/AuthPage/AuthPage';
import RouterPages from './RouterPages';
import { useContext, useEffect } from 'react';
import AuthContext from './stores/auth-context';

function App() {
  let { token, user } = useContext(AuthContext);
  useEffect(() => {}, [token, user]);
  return (
    <div className="App">
      <RouterPages />
    </div>
  );
}

export default App;
