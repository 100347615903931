import React, { useRef, useState } from 'react';
import {
  Table,
  Space,
  Modal,
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import useHttp from '../../hooks/use-http';
import { baseUrl } from '../../share/share';

const TableWorkBook = ({ workBook, employ, setEmploy }) => {
  const [module, setModule] = useState(false);
  const [id, setId] = useState('');
  const [registration, setRegistration] = useState('');
  const [company, setCompany] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [edit, setEdit] = useState('');
  const [moduleDelete, setModuleDelete] = useState(false);
  const [itemForDelete, setItemForDelete] = useState('');

  const { error, sendRequest: saveChangeWorkBook } = useHttp();
  const { errorDelete, sendRequest: deleteWorkBook } = useHttp();

  const columns = [
    {
      title: 'Broj evidencije',
      dataIndex: 'id_registration',
    },
    {
      title: 'Kompanija',
      dataIndex: 'company',
    },
    {
      title: 'Početak rada',
      dataIndex: 'start',
      render: (start) => (
        <>
          {new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(start))}
        </>
      ),
    },
    {
      title: 'Kraj rada',
      dataIndex: 'end',
      render: (end) => (
        <>
          {end &&
            new Intl.DateTimeFormat('en-GB', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(new Date(end))}
        </>
      ),
    },
    {
      title: 'Dužina rada',
      dataIndex: 'sum',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <span className="link" onClick={() => editData(record)}>
            Izmijeni
          </span>
          <span className="link" onClick={() => confirmDeleteField(record)}>
            Obriši
          </span>
        </Space>
      ),
    },
  ];

  const confirmDeleteField = (item) => {
    setModuleDelete(true);
    setItemForDelete(item);
  };

  const saveUploadFiles = async () => {
    let obj = {
      employe_id: employ.id,
      id_registration: registration,
      company: company,
      start: startDate._d,
      end: endDate && endDate._d ? endDate._d : '',
    };

    const changeData = (data) => {
      if (edit) {
        let workBookNew = [...workBook];
        let index = workBookNew.findIndex((item) => item.id === data.id);
        workBookNew[index] = { ...data };
        setEmploy({ ...employ, work_book: workBookNew });
      } else {
        let workBookNew = [...workBook, data];
        setEmploy({ ...employ, work_book: workBookNew });
      }
      closeModal();
    };
    if (edit) {
      let data = await saveChangeWorkBook({
        url: baseUrl + '/work-book/' + id,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: obj,
      });
      changeData(data);
    } else {
      
      let data = await saveChangeWorkBook({
        url: baseUrl +  '/work-book/save',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: obj,
      });

      changeData(data);
    }
  };

  const onChangeData = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    if (name === 'registration') setRegistration(val);
    if (name === 'company') setCompany(val);
  };

  const onChangeDateStart = (event, date) => {
    setStartDate(event);
  };

  const onChangeDateEnd = (event, date) => {
    setEndDate(event);
  };

  const closeModal = () => {
    setRegistration('');
    setCompany('');
    setStartDate('');
    setEndDate('');

    setModule(false);
  };

  const closeModalDelete = () => {
    setModuleDelete(false);
    setItemForDelete('');
  };

  const openNewData = () => {
    setRegistration('');
    setCompany('');
    setStartDate('');
    setEndDate('');

    setEdit(false);
    setModule(true);
  };

  const editData = (item) => {
    setRegistration(item.id_registration);
    setCompany(item.company);
    setStartDate(moment(item.start));
    setEndDate(moment(item.end));
    setId(item.id);

    setEdit(true);
    setModule(true);
  };

  const changeDeletedData = (item) => {
    let index = workBook.findIndex((data) => data.id === item.id);

    workBook.splice(index, 1);

    setEmploy({ ...employ, work_book: [...workBook] });
    closeModalDelete();
  };

  const deleteField = (item) => {
    let data = deleteWorkBook({
      url: baseUrl + '/work-book/' + item.id,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    changeDeletedData(item);
  };

  return (
    <>
      <div className="flex space-between lh-35">
        <h2>Radna knjižica</h2>
        <span className="link" onClick={() => openNewData()}>
          + Dodaj podatke u knjizicu
        </span>
      </div>
      <Table
        columns={columns}
        dataSource={workBook}
        rowKey="id"
        pagination={false}
        scroll={{
          y: 400,
        }}
      />
      <Modal
        title={'Dodaj podatke u knjizicu'}
        centered
        visible={module}
        onOk={() => saveUploadFiles()}
        onCancel={() => closeModal()}
        okButtonProps={{ disabled: false }}
      >
        <label>Broj evidencije: </label>
        <Input
          className="mb-15 mt-5"
          name="registration"
          value={registration}
          onChange={onChangeData}
        />
        <label>Poslodavac: </label>
        <Input.TextArea
          className="mb-15 mt-5"
          name="company"
          value={company}
          onChange={onChangeData}
        />

        <Row>
          <Col span={12}>
            <label>Pocetak radnog odnosa: </label>
            <DatePicker
              className="mb-15 mt-5"
              picker="date"
              name="start"
              onChange={onChangeDateStart}
              value={startDate}
              format={'DD-MM-YYYY'}
            />
          </Col>
          <Col span={12}>
            <label>Kraj radnog odnosa: </label>
            <DatePicker
              className="mb-15 mt-5"
              picker="date"
              onChange={onChangeDateEnd}
              value={endDate}
              format={'DD-MM-YYYY'}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title={'Da li ste sigurni da zelite da obrisete?'}
        centered
        visible={moduleDelete}
        onOk={() => deleteField(itemForDelete)}
        onCancel={() => closeModalDelete()}
        okButtonProps={{ disabled: false }}
      ></Modal>
    </>
  );
};

export default TableWorkBook;
