import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Image, Spin, Modal, Upload, message } from 'antd';
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  InboxOutlined,
} from '@ant-design/icons';

import useHttp from '../../hooks/use-http';
import './EmployesPage.css';
import DataWraper from '../../components/DataWraper/DataWraper';
import Superior from '../../components/Employe/Superior';
import Documentation from '../../components/Employe/Documentation';
import axios from 'axios';
import AuthContext from '../../stores/auth-context';
import TableWorkBook from '../../components/Employe/TableWorkBook';
import { baseUrl } from '../../share/share';

const { Dragger } = Upload;

const Employ = () => {
  const { token, user } = useContext(AuthContext);
  const [isLoadingEmp, setIsLoading] = useState(true);
  const [employ, setEmploy] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [files, setFiles] = useState([]);
  let birthday = '';
  const location = useLocation();
  const params = useParams();
  let navigate = useNavigate();

  const { error, sendRequest: featchEmploy } = useHttp();

  useEffect(async () => {
    const setData = (data) => {
      data.data.birthday = new Date(data.data.birthday);
      if (!error) {
        let emp = { ...data.data };
        emp.work_book.sort((a, b) => new Date(a.start) - new Date(b.start));

        setEmploy(emp);

        setIsLoading(false);
        birthday = new Date(data.data.birthday);
      }
    };
    let data = await featchEmploy({
      url: baseUrl + '/employees/' + params.employId,
    });
    setData(data);
  }, [featchEmploy]);

  if (error) {
    
    setIsLoading(false);
    navigate('/dashbord/employes');
  }

  const openModalUpload = () => {
    setUploadModal(true);
  };
  const closeModal = () => {
    setUploadModal(false);
    setFiles([]);
  };

  const saveUploadFiles = () => {
    const formData = new FormData();
    for (const key in files) {
      formData.append('files[]', files[key]);
    }

    let bearer = 'Bearer ' + token.replaceAll('"', '');
    axios
      .post(
        baseUrl + '/documentation/upload/' + params.employId,
        formData,
        {
          headers: { Authorization: bearer },
        }
      )
      .then((res) => {
        setEmploy({ ...employ, documentation: res.data });
        closeModal();
      });
  };

  const uploadFile = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    setFiles([...files, file]);
    onSuccess(file);
  };

  const uploadConfig = {
    name: 'file',
    multiple: true,
    customRequest: uploadFile,
    onChange(info) {
      const { status } = info.file;

      // if (status !== 'uploading') {
      //   
      // }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  if (isLoadingEmp) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div className="employ-profil">
      <Row className="header">
        <Col span={4}>
          <Image width={200} src={employ.photo} />
        </Col>
        <Col span={20}>
          <h1 className="title">
            {employ.full_name} ({employ.position?.name})
          </h1>
          <div className="data">
            <Row>
              <Col span={7}>
                <strong>Datum rodjenja:</strong>
                <span className="ml-10">
                  {new Intl.DateTimeFormat('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(employ.birthday)}
                </span>
              </Col>
              <Col span={8}>
                <strong>
                  <EnvironmentOutlined />
                </strong>
                <span className="ml-10">{employ.adress}</span>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <strong>
                  <MailOutlined />{' '}
                </strong>
                <span className="ml-10"> {employ.email}</span>
              </Col>
              <Col span={8}>
                <strong>
                  <PhoneOutlined />{' '}
                </strong>
                <span className="ml-10">{employ.num_phone}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="wrapper">
        <Col  span={16}>
          <Col span={24} className=" first p-30">
            <h2>Osnovni podaci</h2>
            <DataWraper label={'Mjesto rodjenja'} data={employ.birth_place} />

            <Row className="data-wraper">
              <Col span={4}>
                <strong>Pol: </strong>
              </Col>
              <Col span={16}>
                {' '}
                {employ.gender === 'male' ? 'Muški' : 'Ženski'}
              </Col>
            </Row>
            <DataWraper label={'JMBG'} data={employ.jmbg} />
            <DataWraper label={'Broj licne karte'} data={employ.id_number} />
            <DataWraper label={'Ime roditelja'} data={employ.parent_name} />
            <DataWraper label={'Neto zarada'} data={employ.neto_selery} />
            <DataWraper label={'Bruto 1 zarada'} data={employ.bruto_1} />
            <DataWraper label={'Bruto 2 zarada'} data={employ.bruto_2} />
            <DataWraper
              label={'Broj radne knjizice'}
              data={employ.num_work_book}
            />
            <DataWraper
              label={'Broj radnih sati'}
              data={employ.number_work_hours}
            />

            <DataWraper
              label={'Probni rad'}
              data={employ.probation_period_end}
              date={true}
            />
            <DataWraper
              label={'Kraj probnog rada'}
              data={employ.probation_period_start}
              date={true}
            />
            <DataWraper label={'Radno mjesto'} data={employ.position?.name} />
            <DataWraper label={'Koefecijent'} data={employ.coefficient && employ.coefficient?.name + ' ' + employ.coefficient?.coefficient} />
          </Col>
          <Col span={24} className="first p-30">
            <TableWorkBook
              workBook={employ.work_book}
              employ={employ}
              setEmploy={setEmploy}
            />
          </Col>
          
        </Col>
        <Col className="first ml-20 p-30" span={7}>
          <h2>Nadredjeni</h2>
          <Superior data={employ.superior} />
          <div className="flex space-between lh-35">
            <h2>Dokumentacija</h2>

            <span className="link" onClick={() => openModalUpload()}>
              + Dodaj dokument
            </span>
          </div>

          <Documentation
            data={employ.documentation}
            employ={employ}
            setEmploy={setEmploy}
          />
        </Col>
       
      </Row>
      <Modal
        title={'Dodajte nova dokumenta'}
        centered
        visible={uploadModal}
        onOk={() => saveUploadFiles()}
        onCancel={() => closeModal()}
        okButtonProps={{ disabled: files.length === 0 }}
      >
        <Dragger {...uploadConfig}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
      </Modal>
    </div>
  );
};

export default Employ;
