import { Row, Col, DemoBox } from 'antd';
import AuthForm from '../../components/Auth/AuthForm';
import classes from './AuthPage.module.css';

const AuthPage = () => {
  return (
    <Row justify="space-around" align="middle" className={classes.h_100}>
      <Col span={6}>
        <AuthForm></AuthForm>
      </Col>
    </Row>
  );
};

export default AuthPage;
