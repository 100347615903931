import { Layout, Menu } from 'antd';
import { useContext } from 'react';
import AuthContext from '../../stores/auth-context';
import './layout.css';

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const { Header } = Layout;
  const logout = (event) => {
    event.preventDefault();
    authCtx.logout();
  };
  return (
    <>
      <Header>
        {/* Right Menu in header */}
        <Menu
          className="right_menu"
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
        >
          <Menu.Item key="1">
            <button className="button_menu" onClick={logout}>
              Logout
            </button>
          </Menu.Item>
        </Menu>
      </Header>
    </>
  );
};

export default MainNavigation;
