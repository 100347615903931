import { Button, Checkbox, Input, Modal, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import useHttp from '../../hooks/use-http';
import { baseUrl } from '../../share/share';

const { Option } = Select;

const Users = () => {
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [user, setUser] = useState({});
    const [company, setCompany] = useState({});
    const [isLoader, setIsLoader] = useState(true);
    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState(true);
    

    const { errorFetch, sendRequest: dateFetch } = useHttp();
    const { errorCompanies, sendRequest: companiesFetch } = useHttp();
    const { changeError, sendRequest: saveChangeData } = useHttp();
    const { saveError, sendRequest: saveNewData } = useHttp();

    useEffect(async () => {
        let prom = [
            dateFetch({
                url: baseUrl + '/users/all',
              }),
              companiesFetch({
                url: baseUrl + '/companies/all',
              }),
            ]
    
        Promise.all(prom).then(datas => {
            const [users, comp] = datas;
            
            setUsers(users);
            setCompanies(comp);
            setIsLoader(false);
        })
    
      }, [companiesFetch]);

      const onCompSelectd = (value) => {
        
        setCompany(value);
      }


      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          
        },
        {
          title: 'Email',
          dataIndex: 'name',
        },
        {
          title: 'Sistem admin',
          dataIndex: 'is_sysadmin',
        },
        // {
        //   title: 'Kompanija',
        //   render: (start) => (<>
        //     {companies[companies.findIndex(item => item.id === company_id)]}
        //   </>)
        // }
      ];

      const changeUser = (event) => {
        let name = event.target.name;
        let val = name !== 'is_sysadmin' ? event.target.value : event.target.checked;
    
        setUser({ ...user, [name]: val });
        if(disabled === true) setDisabled(false);
      }

      const closeModal = () => {
        setModal(false);
        setUser({});
      };

      const newUser = (event) => {
        event.preventDefault();
        setUser({});
        setModal(true);
        setDisabled(true);
        setCompany({});
      };
      const saveChangeUser = async () => {
        setIsLoader(true)

        let obj = {
            name: user.name,
            email: user.email,
            password: user.password,
            password_confirm: user.password,
            is_sysadmin: user.is_sysadmin ? true : false,
            company_id: company
        }
       
        ;
        let data = await saveNewData({
            url: baseUrl + '/users/register',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: obj,
          })
          closeModal();
          setUsers([...data.user])
          setIsLoader(false)
      }


    return ( <>
        <Button type="primary" onClick={newUser}>
            + Nova Korisnik
        </Button>
        {!isLoader && ( 
        <Table 
            columns={columns}
            dataSource={users}
            rowKey="id"
            pagination={false}
            scroll={{
                y: 'calc(100vh - 190x)',
                x: '100%',
            }}
        />)}
        <Modal
            title={'Novi korisnik'}
            centered
            visible={modal}
            onOk={() => saveChangeUser()}
            onCancel={() => closeModal()}
            okButtonProps={{ disabled: disabled }}
        >
            <label>Ime korisnika: </label>
            <Input 
                value={user.name} 
                onChange={changeUser} 
                className="mb-15 mt-5"
                name="name" 
            />
            <label>Email: </label>
            <Input 
                value={user.email} 
                onChange={changeUser} 
                className="mb-15 mt-5"
                name="email" 
            />

            <label>Password: </label>
            <Input 
                value={user.password} 
                onChange={changeUser} 
                className="mb-15 mt-5"
                name="password" 
            />
            <div>
                <label>Sistem admin: </label>
                <Checkbox
                    checked={user.is_sysadmin}
                    onChange={changeUser} 
                    name="is_sysadmin" 
                />
            </div>
            

            <label>Kompanija: </label>
            <Select 
                placeholder="Izaberite koeficijenti"  
                onChange={onCompSelectd} 
                value={company} 
                className="mb-15 mt-5 mw-200" 
                >
                {companies.map((item) => {
                    return(
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                })}
            </Select>
        </Modal>
    </> );
}
 
export default Users;