import React from 'react';
import { Row, Col } from 'antd';
import './DataWraper.css';

const DataWraper = (props) => {
  let dateView;
  if (props.date) {
    let date = new Date(props.data);
    dateView = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(date);
  }

  return (
    <Row className="data-wraper">
      <Col span={props.span ? props.span : 4}>
        <strong>{props.label}: </strong>
      </Col>
      <Col span={props.span ? 24 - props.span : 16}>
        {props.date ? dateView : props.data}
      </Col>
    </Row>
  );
};

export default DataWraper;
