import React, { useState } from 'react';
import {
  FilePdfOutlined,
  FileZipOutlined,
  FileWordOutlined,
  CodeOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Col, Image, Modal, Row } from 'antd';
import './Employe.css';
import useHttp from '../../hooks/use-http';
import { baseUrl } from '../../share/share';

const icons = {
  pdf: () => {
    return (
      <>
        <FilePdfOutlined />
      </>
    );
  },
  zip: () => {
    return (
      <>
        <FileZipOutlined />
      </>
    );
  },
  docx: () => {
    return (
      <>
        <FileWordOutlined />
      </>
    );
  },
  xml: () => {
    return (
      <>
        <CodeOutlined />
      </>
    );
  },
};

const Documentation = ({ data, employ, setEmploy }) => {
  const [modal, setModal] = useState(false);
  const [currentFile, setCurrentFile] = useState('');
  const click = (item) => {
    if (item.type === 'png' || item.type === 'jpge' || item.type === 'jpg')
      return;
    window.open(item.photo);
  };

  const { error, sendRequest: deleteFileRequest } = useHttp();

  const changeDataDelete = (item) => {
    let index = data.findIndex((i) => i.id === item.id);

    data.splice(index, 1);

    setEmploy({ ...employ, documentation: [...data] });
    closeModal();
  };

  const confirmDelete = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentFile(item);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setCurrentFile('');
  };

  const deleteFile = (item) => {
    let data = deleteFileRequest({
      url: baseUrl + '/documentation/' + item.id,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    changeDataDelete(item);
  };
  let documentations = data.map((item) => {
    let date = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(item.created_at));
    return (
      <Col
        span={24}
        className="document square"
        key={item.id}
        onClick={() => click(item)}
      >
        {(item.type === 'png' ||
          item.type === 'jpge' ||
          item.type === 'jpg') && (
          <Image width={100} height={100} src={item.photo} />
        )}
        {item.type !== 'png' && item.type !== 'jpge' && item.type !== 'jpg' && (
          <div className="icon">{icons[item.type]()}</div>
        )}
        <div className="info">
          <div>{item.name}</div>
          <div>{date}</div>
        </div>
        <div className="delete" onClick={(event) => confirmDelete(event, item)}>
          <CloseCircleOutlined />
        </div>
      </Col>
    );
  });
  return (
    <>
      <Row className="documentations">{documentations}</Row>
      <Modal
        title={'Da li zelite da obrisite file ' + currentFile.name}
        centered
        visible={modal}
        onOk={() => deleteFile(currentFile)}
        onCancel={() => closeModal()}
      ></Modal>
    </>
  );
};

export default Documentation;
