import React, { useContext, useEffect, useState } from 'react';

import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import LayoutComponent from './components/Layout/LayoutComponent';
import EmployesPage from './pages/EmployesPage/EmployesPage';
import AuthPage from './pages/AuthPage/AuthPage';
import Employ from './pages/EmployesPage/Employ';
import EmployeEdit from './pages/EmployesPage/EmployeEdit';
import WorkPosition from './pages/WorkPosition/WorkPosition';
import Company from './pages/Company/Company';
import AuthContext from './stores/auth-context';
import Coefficient from './pages/Coefficient/Coefficient';
import Users from './pages/Users/Users';

const RouterPages = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if(user && (typeof user === 'string' && JSON.parse(user).is_sysadmin || user.is_sysadmin)) setIsAdmin(true);
  }, [user]);
  return (
    <Routes>
      {/* <Route path="*" element={<Navigate replace to="dashbord/employes" />} /> */}
      <Route index element={<Navigate replace to="dashbord/employes" />} />
      <Route path="dashbord" element={<LayoutComponent />}>
        <Route path="employes" element={<EmployesPage />} />
        <Route path="employ/:employId" element={<Employ />} />
        <Route path="employ/edit/:employId" element={<EmployeEdit />} />
        <Route path="work-position" element={<WorkPosition />} />
        {/* {user && JSON.parse(user).is_sysadmin && ( */}
        <Route path="companies" element={<Company />} />
        {/* )} */}
        {/* {user && JSON.parse(user).is_sysadmin && ( */}
        <Route path="coefficient" element={<Coefficient />} />
        {/* )} */}
        {/* {user && JSON.parse(user).is_sysadmin && ( */}
        <Route path="users" element={<Users />} />
        {/* )} */}
      </Route>

      <Route path="/login" element={<AuthPage />} />
    </Routes>
  );
};
// </RequireAuth>

export default RouterPages;
