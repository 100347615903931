import { Button, Input, Modal, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import useHttp from '../../hooks/use-http';
import { baseUrl } from '../../share/share';

const Company = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [modal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [company, setCompany] = useState({});

  const { errorFetch, sendRequest: companiesFetch } = useHttp();
  const { changeError, sendRequest: saveChangeCompanes } = useHttp();
  const { saveError, sendRequest: saveNewCompanes } = useHttp();

  useEffect(async () => {
    const transformData = (data) => {
      setCompanies(data);
      setIsLoader(false);
    };

    let data = await companiesFetch({
      url: baseUrl + '/companies/all',
    });

    transformData(data);
  }, [companiesFetch]);

  const saveChangeCompany = async () => {
    setIsLoader(true);
    const changeData = () => {
      closeModal();
      let index = companies.findIndex((item) => item.id === company.id);
      companies[index].name = company.name;
      setCompanies([...companies]);
      setIsLoader(false);
    };

    const newData = (data) => {
      closeModal();

      companies.push({ id: data.id, name: data.name });
      setCompanies([...companies]);
      setIsLoader(false);
    };

    if (company.id) {
      let data = await saveChangeCompanes({
        url: baseUrl + '/companies/' + company.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { name: company.name },
      });
      changeData(data);
    } else {
      let data = await saveNewCompanes({
        url: baseUrl + '/companies/create',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { name: company.name },
      });
      newData(data);
    }
  };

  const changeName = (event) => {
    let name = event.target.value;

    setCompany({ ...company, name: name });
    setDisabled(false);
  };

  const newPosition = (event) => {
    event.preventDefault();
    setCompany({});
    setModal(true);
    setDisabled(true);
  };

  const editPosition = (item) => {
    setCompany(item);
    setModal(true);
    setDisabled(true);
  };

  const closeModal = () => {
    setModal(false);
    setCompany({});
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '80%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {/* <Popconfirm
            title="Da li ste sigurni da želite da obrišete poziciju?"
            onConfirm={() => deletePosition(record.id)}
          >
            <a>Obriši</a>
          </Popconfirm> */}
          <Button onClick={() => editPosition(record)}>Edit</Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Button type="primary" onClick={newPosition}>
        + Nova kompanija
      </Button>
      {!isLoader && (
        <Table
          columns={columns}
          dataSource={companies}
          rowKey="id"
          pagination={false}
          scroll={{
            y: 'calc(100vh - 190x)',
            x: '100%',
          }}
        />
      )}
      <Modal
        title={company.id ? 'Promijeni ime kompanije' : 'Nova kompanija'}
        centered
        visible={modal}
        onOk={() => saveChangeCompany()}
        onCancel={() => closeModal()}
        okButtonProps={{ disabled: disabled }}
      >
        <Input value={company.name} onChange={changeName} />
      </Modal>
    </>
  );
};

export default Company;
