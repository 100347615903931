import React, { useContext, useEffect, useState } from 'react';
import { Steps, Button, message, Form } from 'antd';
import FirstStepEditForm from '../../components/Employe/FirstStepEditForm';
import SecondStepEditForm from '../../components/Employe/SecondStepEditForm';
import ThirdStepEditForm from '../../components/Employe/ThirdStepEditForm';
import useHttp from '../../hooks/use-http';
import axios from 'axios';
import AuthContext from '../../stores/auth-context';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { baseUrl } from '../../share/share';

const { Step } = Steps;

const steps = [
  {
    id: 1,
    title: 'Osnovni podaci',
  },
  {
    id: 2,
    title: 'Pozicija',
  },
  {
    id: 3,
    title: 'Last',
  },
];

const EmployeEdit = () => {
  // auth data
  const { token, user } = useContext(AuthContext);
  //state for steps
  const [current, setCurrent] = React.useState(0);
  //employe obj
  const [employe, setEmploye] = useState({});

  //Position select
  const [position, setPosition] = useState({});
  const [superior, setSuperior] = useState({});

  const [positions, setPositions] = useState([]);
  const [superiors, setSuperiors] = useState([]);
  //loader
  const [isLoader, setIsLoader] = useState(true);

  const [stepForm] = Form.useForm();
  //state for file
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const { employId } = useParams();
  //cariables for coefficients
  const [coefficients, setCoefficients] = useState([]);

  //http object for get employe if it is edit form
  const { isErrorEmp, sendRequest: getEmplye } = useHttp();
  const { isErrorPos, sendRequest: getPositions } = useHttp();
  const { isErrorSup, sendRequest: getSuperior } = useHttp();
  const { isErrorCoeff, sendRequest: coeffFetch } = useHttp();

  useEffect(() => {
    //function for set data in state
    const getDataEmploy = (data) => {
      let employ = data.data;
      if (employ.birthday) employ.birthday = moment(new Date(employ.birthday));
      if (employ.probation_period_end)
        employ.probation_period_end = moment(
          new Date(employ.probation_period_end)
        );
      if (employ.probation_period_start)
        employ.probation_period_start = moment(
          new Date(employ.probation_period_start)
        );
      // employ.indefinitely_work = employ.indefinitely_work ? true : false;
      setPosition({ ...employ.position });
      employ.position = employ.position.name;
      let supIndex = superiors.findIndex(item => item.id === employ.id)
      setSuperior({...employ.superior});
      employ.superior = employ.superior.full_name;
      

      setEmploye(employ);

      let img = {
        uid: '-1',
        status: 'done',
        url: employ.photo,
      };
      setFileList([img]);
    };

    //request for get employe
    let prom = [
      getPositions({ url:  baseUrl + '/work-postitions/all' }),
      getSuperior({
        url:
          baseUrl + '/employees/superiors/' +
          JSON.parse(user).company.id,
      }),
      coeffFetch({
        url: baseUrl + '/coefficient/all',
      })
    ];
    if (employId != 'create')
      prom.push(
        getEmplye({ url: baseUrl +  '/employees/' + employId })
      );
    Promise.all(prom).then(async (datas) => {
      const [pos, sup, coeff, emp] = datas;
      
      if (emp) await getDataEmploy(emp);
      setPositions([...pos.data]);
      setSuperiors([...sup.data]);


      
      setCoefficients([...coeff]);
      setIsLoader(false);
    });
  }, []);

  const next = () => {
    setCurrent(current + 1);
    let num = 1;
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = (values) => {
    //get data
    const data = stepForm.getFieldsValue(true);

    data.work_position_id = position.id;
    data.superior = superior.id;
    
    
    // create form data
    const formData = new FormData();

    // add datas in form data
    for (const key in data) {
      formData.append(key, data[key]);
    }

    // check (if edit) have file
    let file =
      fileList[0] && fileList[0].originFileObj
        ? fileList[0].originFileObj
        : fileList[0].url;

    //add file in form data
    formData.append('photo', file);

    // make bearer for autrorizate
    let bearer = 'Bearer ' + token.replaceAll('"', '');
    // check edit or create employ
    if (employId != 'create') {
      // edit request
      axios
        .post(baseUrl + '/employees/' + employId, formData, {
          headers: { Authorization: bearer },
        })
        .then((res) => {
          //go to page employ
          navigate('/dashbord/employ/' + res.data.id);
        });
    } else {
      // create request
      axios
        .post(baseUrl + '/employees/create', formData, {
          headers: { Authorization: bearer },
        })
        .then((res) => {
          //go to page employ
          navigate('/dashbord/employ/' + res.data.id);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="edit-employe">
      {!isLoader && (
        <Form
          name="basic"
          form={stepForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={employe}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {steps[current].id === 1 && (
              <FirstStepEditForm
                employe={employe}
                fileList={fileList}
                setFileList={setFileList}
              />
            )}
            {steps[current].id === 2 && (
              <SecondStepEditForm
                employe={employe}
                positions={positions}
                superiors={superiors}
                setPosition={setPosition}
                setSuperior={setSuperior}
                coefficients={coefficients}
              />
            )}
            {steps[current].id === 3 && <ThirdStepEditForm employe={employe} />}
          </div>

          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => message.success('Processing complete!')}
                htmlType="submit"
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default EmployeEdit;
