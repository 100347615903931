import React, { useEffect, useState } from 'react';
import useHttp from '../../hooks/use-http';
import { Table, Space, Col, Input, Row, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../share/share';

const columns = [
  {
    title: 'Name',
    dataIndex: 'full_name',
    width: '20%',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    filters: [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ],
    width: '20%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <Link to={`/dashbord/employ/${record.id}`}>View </Link>
        <Link to={`/dashbord/employ/edit/${record.id}`}>Edit </Link>
      </Space>
    ),
  },
];
//Height table
let scrollTable = window.innerHeight - 200;

const EmployesPage = () => {
  const [orgEmployes, setOrgEmployes] = useState([]);
  const [employes, setEmployes] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const navigate = useNavigate();

  const { error, sendRequest: featchEmployes } = useHttp();

  useEffect(async () => {
    const transformData = (data) => {
      setEmployes([...data.data]);
      setOrgEmployes([...data.data]);
      setIsLoader(false);
    };
    let data = await featchEmployes({
      url: baseUrl + '/employees/all',
    });

    transformData(data);
  }, [featchEmployes]);

  const newEmploye = () => {
    navigate('/dashbord/employ/edit/create');
  };

  const filterEmployes = (event) => {
    let val = event.target.value;

    let copyEmplyes = orgEmployes.filter((item) => {
      return item.full_name.toLowerCase().indexOf(val) > -1;
    });
    
    setEmployes([...copyEmplyes]);
  };
  return (
    <>
      <Row className="action-bar mb-20">
        <Col span={12}>
          <Input
            className="mw-300"
            placeholder="Pretrazi po imenu"
            onChange={filterEmployes}
          />
        </Col>
        <Col span={12}>
          <Button type="primary" className="right" onClick={newEmploye}>
            + Novi zaposleni
          </Button>
        </Col>
      </Row>
      {!isLoader && (
        <Table
          columns={columns}
          dataSource={employes}
          rowKey="id"
          pagination={false}
          scroll={{
            y: scrollTable,
          }}
        />
      )}
    </>
  );
};

export default EmployesPage;
