import { Col, Image, Row } from 'antd';
import React from 'react';
import './Employe.css';

const Superior = ({ data }) => {
  return (
    <>
      <Row className="superior">
        <Col span={7}>
          <Image width={90} src={data.photo} />
        </Col>
        <Col span={17}>
          <div className="full_name">{data.full_name}</div>
          <div className="position">{data.position.name}</div>
          <div className="email">{data.email}</div>
        </Col>
      </Row>
    </>
  );
};

export default Superior;
